// import logo from './logo.svg';
// import './App.css';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';


import Register from './components/register';
import Index from './components/index';
import Login from './components/login';
import ForgotPassword from './components/forgot-password';
import SlotList from './components/slot-list';

// function App() {
//   return (
//     <div className="App">
//       {/* <Register/> */}
//       <Index/>
//       {/* <Login/> */}
//       {/* <ForgotPassword/> */}
//       {/* <SlotList/> */}
      
//     </div>
//   );
// }

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/index" element={<Index />} />
        <Route path="/forgot" element={<ForgotPassword />} />
        <Route path="/slot" element={<SlotList />} />
      </Routes>
    </Router>
  );
}


export default App;



// import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

// import LoginPage from './components/login/login';
// import RegisterPage from './components/register/regirster';
// import HomePage from './components/homepage/home';
// import NoteDetailPage from './components/NoteDetail/NoteDetailPage';
// import AddNotePage from './components/addnotes/addnotes';
// import ExportPage from './components/exports/export';
// import NotFoundPage from './components/NotFound';


// function App() {
//   return (
//     <Router>
//       <Routes>
//         <Route path="/" element={<LoginPage />} />
//         <Route path="/register" element={<RegisterPage />} />
//         <Route path="/home" element={<HomePage />} />
//         <Route path="/note/:id" element={<NoteDetailPage />} />
//         <Route path="/add-note" element={<AddNotePage />} />
//         <Route path="/export" element={<ExportPage />} />
//         <Route path="*" element={<NotFoundPage />} />
//       </Routes>
//     </Router>
//   );
// }

// export default App;
