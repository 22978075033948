import React from 'react';

// import { Button, Col, Container, Form, Row } from 'react-bootstrap';
// import { Button, Form } from 'react-bootstrap';

// import Header from './include/header';
// import Footer from './include/footer';


const SlotList = () => {

  return (
    <>
    {/* <Header/> */}
    <div>

        <div className="slots-div mt-1">
            {/* <!-- <p className="text-center m-0 ">No time slots found.</p> --> */}
                                                    
            <div className="flex-wrap align-items-center justify-content-center front-slot-data appointment-slot-data" id="slotData">
                <span className="badge badge-lg slots-item  bg-success time-slot">12:00 AM - 12:05 AM</span>
                <span className="badge badge-lg slots-item  bg-success time-slot">12:10 AM - 12:15 AM</span>
                <span className="badge badge-lg slots-item  bg-success time-slot">12:20 AM - 12:25 AM</span>
                <span className="badge badge-lg slots-item  bg-success time-slot">12:30 AM - 12:35 AM</span>
                <span className="badge badge-lg slots-item  bg-success time-slot">12:40 AM - 12:45 AM</span>
                <span className="badge badge-lg slots-item  bg-danger  time-slot">12:50 AM - 12:55 AM</span>
                <span className="badge badge-lg slots-item  bg-success time-slot">01:00 AM - 01:05 AM</span>
                <span className="badge badge-lg slots-item  bg-success time-slot">01:10 AM - 01:15 AM</span>
                <span className="badge badge-lg slots-item  bg-danger  time-slot">01:20 AM - 01:25 AM</span>
                <span className="badge badge-lg slots-item  bg-success time-slot">01:30 AM - 01:35 AM</span>
                <span className="badge badge-lg slots-item  bg-success time-slot">01:00 AM - 01:05 AM</span>
                <span className="badge badge-lg slots-item  bg-success time-slot">01:10 AM - 01:15 AM</span>
                <span className="badge badge-lg slots-item  bg-danger  time-slot">01:20 AM - 01:25 AM</span>
                <span className="badge badge-lg slots-item  bg-success time-slot">01:30 AM - 01:35 AM</span>
            </div>
        </div>
      
    </div>
    {/* <Footer/> */}
    </>
  );
}

export default SlotList;
