import React from 'react';
import logo from '../images/logo.svg';
import { Navbar, Container, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Header = () => {
  return (
    <Navbar bg="light" expand="md">
      <Container>
        <Navbar.Brand as={Link} to="/">
          <img src={logo} alt="logo" width="200" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbar-nav" className="custom-toggler" />
        <Navbar.Collapse id="navbar-nav" className="justify-content-end">
          <Nav>
            <Nav.Link as={Link} to="/index" className="btn btn-theme mb-3 mb-md-0 border-radius mr-md-3">
              Book An Appointment
            </Nav.Link>
            <Nav.Link as={Link} to="/" className="btn-outline-theme mb-3 mb-md-0 border-radius">
              Login
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
