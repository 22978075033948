import React from 'react';
import { Link } from 'react-router-dom';

import Header from './include/header';
import Footer from './include/footer';
// import logo from './images/logo.svg'

const ForgotPassword = () => {

  return (
    <>
      <Header />
      <section className="book-appointment-section bg-secondary p-t-80 p-b-80">
        <div className="container">
          <div className="row">
            <div className="col-6 m-auto">
              {/* <div className="logo text-center mb-5">
              <img src={logo} alt="logo" width="200"/>
            </div> */}

              <div className="appointment-form form-wraper">
                <form
                  className="book-appointment-form bg-white rounded-20 box-shadow"
                  action="#"
                  method="post"
                >
                  <div className="row">
                    <div className="col-12 mb-md-3 mb-3">
                      <h4 className="text-center">Forgot Password ? </h4>
                      <p className="text-gray-700 mt-3 text-center">
                        Forgot your password? No problem. Just let us know your
                        email address and we will email you a password reset
                        link that will allow you to choose a new one.
                      </p>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group">
                        <label
                          className="form-label"
                          htmlFor="first_name"
                        >
                          Email: <span className="required">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Email"
                          value=""
                        />
                      </div>
                    </div>

                    <div className="col-12 text-center mt-4 d-flex">
                      <button
                        className="btn btn-theme mb-3 mb-lg-0 border-radius w-75 text-uppercase"
                        type="submit"
                        id="saveBtn"
                        value="submit"
                      >
                        Email Password Reset Link
                      </button>
                      
                      <button className="btn btn-secondary mb-3 mb-lg-0 border-radius w-25 ml-2 text-uppercase text-white text-center px-4" type="button">
                          <Link to="/" className='white-text' style={{color: 'white'}}>Cancel</Link>
                      </button>
      
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default ForgotPassword;
