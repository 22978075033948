import React from 'react';
import Header from './include/header';
import Footer from './include/footer';
import { Link } from 'react-router-dom';

// import logo from './images/logo.svg';

const Login = () => {

  return (
    <>
    <Header/>
    <div>
        <section className="book-appointment-section bg-secondary p-t-80 p-b-80">
            <div className="container">
                <div className="row">
                    {/* <div className="logo text-center mb-5">
                           <img src={logo} alt="logo" width="200"/>
                        </div> */}
                    <div className="col-6 m-auto">
                        <div className="appointment-form form-wraper">
                            <form className="book-appointment-form bg-white rounded-20 box-shadow" action="#" method="post">
                                <div className="row">

                                    <div className="col-12 mb-md-3 mb-3">
                                        <h4 className="text-center">Sign In </h4>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="form-group">
                                            <label className="form-label" for="first_name">Email: <span className="required">*</span></label>
                                            <input type="text" className="form-control"  placeholder="Email" value=""/>
                                        </div>
                                    </div>

                                    <div className="col-lg-12">
                                        <div className="form-group">
                                            <label className="form-label" for="first_name">Password: <span className="required">*</span></label>
                                            <input type="password" className="form-control"  placeholder="Password" value=""/>
                                        </div>
                                        
                                        <Link to="/forgot" className="link-info fs-6 text-decoration-none">
                                            Forgot your password?
                                        </Link>
                                    </div>

                                    <div className="col-12 text-center mt-4">
                                        <button className="btn btn-theme mb-3 mb-lg-0 border-radius w-100 text-uppercase" type="submit" id="saveBtn" value="submit">Login
                                        </button>
                                    </div>

                                    <div className="col-12 text-center">
                                        <div className="d-flex align-items-center mb-10 mt-3">
                                            <span className="text-gray-700 me-2"> New Here?</span>
                                            <Link to="/register" className="link-info fs-6 text-decoration-none">
                                                Create an Account
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
    <Footer/>
    </>
  );
}

export default Login;
