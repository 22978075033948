import React, { useState } from 'react';
import Header from './include/header';
import Footer from './include/footer';
// import CalendarIcon from 'react-calendar';
import { ReactComponent as CalendarIcon } from './images/calendar.svg';
import SlotsList from './slot-list';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
// import { Form, Row, Col, Button } from 'react-bootstrap';
// import '!!style-loader!css-loader!bootstrap/dist/css/bootstrap.min.css';


const Index = () => {

    // const SlotSelector = () => {
    const [selectedDate, setSelectedDate] = useState(null);
    const [availableSlots, setAvailableSlots] = useState([]);
      
    const handleDateChange = (date) => {
      setSelectedDate(date);
      // Call API to fetch available time slots for the selected date
      // and update the availableSlots state accordingly
      setAvailableSlots(['9:00 AM', '10:00 AM', '11:00 AM']);
    };


    // const SelectDate = () => {
    //     const [date, setDate] = useState(new Date());
    //     const [slots, setSlots] = useState([])

    //     const handleSateChange = date => {
    //         //API to fetch available slots
    //         setSlots(['10:00am ','12:00am', '1:00pm']);
    //         setDate(date);
    //     }
    // };

    
  return (
        <>
        <Header />
        <div>
            <section className="book-appointment-section bg-secondary p-t-100 p-b-100">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="appointment-form form-wraper">
                                <form className="book-appointment-form bg-white rounded-20 box-shadow" action="#" method="post">

                                    <div className="row">

                                        <div className="col-12 d-flex align-items-center justify-content-between flex-wrap mb-md-5 mb-3">
                                            <h4>Book Appointment</h4>
                                            <div className="form-check d-flex align-items-center mb-2 mt-2 mt-md-0 ms-auto">
                                                <input className="form-check-input form-lg-check" type="checkbox" value="1" id="patientaccount"/>
                                                <label className="form-check-label text-gray-200 fw-light ml-2" for="patientaccount">
                                                    Already Have Patient Account
                                                </label>
                                            </div>
                                        </div>

                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label className="form-label" for="Location">Location: <span className="required">*</span></label>
                                                <select className="form-control" data-control="select2" name="">
                                                    <option selected="selected" value="">Select Location</option>
                                                    <option value="1">Allahabad</option>
                                                    <option value="2">Delhi</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label className="form-label" for="Clinic">Clinic: <span className="required">*</span></label>
                                                <select className="form-control" data-control="select2" name="">
                                                    <option selected="selected" value="">Select Clinic </option>
                                                    <option value="1">Clinic 1</option>
                                                    <option value="2">Clinic 2</option>
                                                    <option value="3">Clinic 3</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label className="form-label" for="Specialist">Specialist: <span className="required">*</span></label>
                                                <select className="form-control" data-control="select2" name="">
                                                    <option selected="selected" value="">Select Specialist / Services </option>
                                                    <option value="1">Dermatologists</option>
                                                    <option value="2">Cardiologists</option>
                                                    <option value="3">Neuroradiology</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label className="form-label" for="Doctor">Doctor : <span className="required">*</span></label>
                                                <select className="form-control" data-control="select2" name="">
                                                    <option selected="selected" value="">Select Doctor  </option>
                                                    <option value="1">Sansa Gomez (9008)</option>
                                                    <option value="2">Amit  Singh (9009)</option>
                                                    <option value="3">Sonia Bush (9002)</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label className="form-label" for="Live Consultation">Live Consultation (On Video Conference) *: <span className="required">*</span></label>
                                                <select className="form-control" data-control="select2" name="">
                                                    <option value="1">No</option>
                                                    <option value="2">Yes</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div class="col-lg-6">
                                          <div class="form-group">
                                            <label class="form-label" for="AppointmentDate">Appointment Date: <span class="required">*</span></label>
                                            <div class="input-group-cal mb-3">
                                              <DatePicker
                                                selected={selectedDate}
                                                onChange={handleDateChange}
                                                dateFormat="MM/dd/yyyy"
                                                placeholderText='Select a date'
                                                className="form-control datepicker border-cal-custom"
                                                id="datepicker"
                                              />
                                              <div class="input-group-append">
                                                <span class="input-group-text-appoint-date">
                                                  <span class="fa fa-calendar" id='cal-icon'><CalendarIcon/></span>
                                                </span>
                                              </div>
                                            </div>
                                            
                                          </div>
                                        </div>

                                        {/* <div className="col-lg-6">
                                            <div className="form-group">
                                                <label className="form-label" for="AppointmentDate">Appointment Date: <span className="required">*</span></label>
                                                <div className="input-group mb-3">
                                                    <DatePicker
                                                        selected={selectedDate}
                                                        onChange={handleDateChange}
                                                        dateFormat="MM/dd/yyyy"
                                                        placeholderText='Select a date'
                                                        type='text' className="date form-control mb-3 hasDatepicker" 
                                                    />
                                                    <div className="input-group-append">
                                                        <span className="input-group-text cal-icon"><CalendarIcon/></span>
                                                    </div>

                                                </div>
                                            </div>
                                        </div> */}

                                        <div className="col-12">
                                            <div className="form-group">
                                                <div className="d-flex align-items-center flex-wrap">
                                                    <label for="Available Slots" className="form-label">Available Slots: <span className="required"></span></label>
                                                    <div className="d-flex align-items-center">
                                                        <div className="form-check d-flex align-items-center mb-2 ml-3">
                                                            <input className="form-check-input-1 form-check-danger me-2 mt-0" type="checkbox" value="" id="booked"/>
                                                            <label className="form-check-label fw-light fs-small ml-2" for="booked">
                                                                Booked
                                                            </label>
                                                        </div>
                                                        <div className="form-check d-flex align-items-center mb-2 ml-3">
                                                            <input className="form-check-input-1 form-check-success me-2 mt-0" type="checkbox" value="" id="available"/>
                                                            <label className="form-check-label fw-light fs-small ml-2" for="available">
                                                                Available
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>

                                              {/* <!-- slot date --> */}
                                            {/* <div id="slots-box">
                                            </div> */}

                                            {availableSlots.length > 0 ? (
                                                <div>
                                                    <SlotsList/>
                                                    {/* <p>This slots are available</p> */}
                                                </div>
                                            ):(
                                                <p>Please select a date to view available slots.</p>
                                            )}

                                        </div>
                                        </div>
                                       
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label className="form-label" for="first_name">First Name: <span className="required">*</span></label>
                                                <input type="text" className="form-control"  placeholder="First Name" value=""/>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label className="form-label" for="last_name">Last Name:</label>
                                                <input type="text" className="form-control" value="" placeholder="Last Name"/>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label className="form-label" for="email">Email: <span className="required">*</span></label>
                                                <input type="email" className="form-control" value="" placeholder="Email"/>
                                            </div>
                                        </div>

                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label className="form-label" for="Phone">Phone: <span className="required">*</span></label>
                                                <input type="email"  className="form-control" value="" placeholder="Phone"/>
                                            </div>
                                        </div>
                                         
                                        <div className="col-lg-12">
                                            <div className="form-group">
                                                <label className="form-label" for="Message">Message: <span className="required"></span></label>
                                                <textarea className="form-control" placeholder="Message.."></textarea>
                                            </div>
                                        </div>
                                              
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label className="form-label" for="Address">Address: <span className="required">*</span></label>
                                                <input type="text"  className="form-control" value="" placeholder="Address"/>
                                            </div>
                                        </div>

                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label className="form-label" for="Payment Type">Payment Method: <span className="required">*</span></label>
                                                <select className="form-control" data-control="select2" name="payment_type">
                                                    <option selected="selected" value="">Select payment method</option>
                                                    <option value="1">Online</option>
                                                    <option value="2">Manually</option>
                                                </select>
                                            </div>
                                        </div>


                                        <div className="col-12 text-center">
                                            <button className="btn btn-theme mb-3 mb-lg-0 border-radius" type="submit" id="saveBtn" value="submit">Confirm Booking</button>
                                        </div>

                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <Footer/>
        </>
  )
}

export default Index;