import React from 'react';



const Footer = () => {

  return (
    <>
        <footer className="bg-dark">
            <div className="container">
                <div className="row">
                    <div className="col-12 order-4 border-top-primary text-center">
                        <p className="text-white fw-light py-4 mb-0">All Rights Reserved © 2022 Clinic  Management System</p>
                    </div>
                </div>
            </div>
        </footer>
    </>
  );
}

export default Footer;